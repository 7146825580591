import Helmet from "react-helmet"
import React from "react"
import HomepageLayout from "src/layouts/homepage"
import Chromelpez5 from "src/components/chromelpez5"
import {isIeEdge,isChrome,isFirefox,isSafari} from "@tightrope/lpscripts/browserdetect"
import Disclaimers from "@tightrope/disclaimers"
import ChunkyFooter from "src/components/chunkyfooter"
import RawFooter from "@tightrope/footerlinks/rawfooter.js"

const footerData = import("./data/footerData.json");
const chromelpezData = import("./data/data.json");

export default function prdbest() {

  return(
    <HomepageLayout>
    <Helmet>
     <style type="text/css">{`
       body {
         min-height:100vh;
         width: 100%;
         min-width: 728px;
         background: #E1EEF8;
         background: linear-gradient(to top right, #E1EEF8, #F7FCFF);
         padding: 0;
         margin: 0;
         background-repeat: no-repeat;
         background-size: cover;
         font-family: 'Roboto', sans-serif;
       }

       .footerlinks-module--br {
         display: none!important;
       }
       #chromelpez-module--chromelpez .chromelpez-module--step-grid {
         margin: 20px auto;
       }
       #disclaimers-module--disclaimers {
        margin:28px 0 0 0;
        width:100%;
        }
  
       #disclaimers-module--disclaimers p {
        font-weight: 300;
        color: #333;
        font-size: 13px;
        text-align: center;
        font-family: Roboto,sans-serif;
        max-width: 580px;
        margin: 0 auto;
        }
  
        #disclaimers-module--disclaimers p a {
        font-weight:700;
        }
       footer {
        background:#fff;
        font-family: 'Roboto', sans-serif !important;
        display: block;
        position: relative;
        color: #333;
        border-radius: 5px;
        width: 100%;
      }
      footer ul {
        position: relative;
        list-style: none;
        margin: 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        text-align: right;
        color: #333;
        font-size: 12px;
        padding: 12px 0;
      }
      footer ul li {
        padding: 0 4px 0 0;
      }
      footer ul li:last-child {
        padding: 0;
      }
      footer ul li:after {
        content: '|';
        padding: 0 0 0 4px;
      }
      footer ul li:nth-child(6):after, footer ul li:nth-child(7):after, footer ul li:last-child:after {
        content: none;
        padding: 0;
      }
      footer ul a {
        color: #333;
      }
      @media only screen and (max-width: 768px) {
    
        footer {
          position: relative;
          display: flex;
          justify-content:center;
          align-items:center;
          color: #333;
          width: 100%;
          right:auto;
          bottom:auto;
      }
    
      footer ul {
        position: relative;
        display: flex;
        justify-content:center;
        align-items:center;
    }
    `}
    </style>
    <style type="text/css">
         </style>
    <title>Print any web page easily - Easy Print</title></Helmet>
    <section>
      <Chromelpez5 data={chromelpezData}>
      <Disclaimers language='en-chrome'></Disclaimers>
      </Chromelpez5>
      <ChunkyFooter data={footerData}><Disclaimers language='en-chrome'></Disclaimers></ChunkyFooter>
      </section>
      <RawFooter></RawFooter>
    </HomepageLayout>
  )
}
